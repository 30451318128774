<template>
<div>
	<div class="row">
		<div>
			<q-input filled v-model="node" type="text" name="node" label="node"/>
		</div>
		<div>
			<q-btn dense @click="getnode" label="get" />
		</div>
	</div>

	<div v-if="done">
		<h4 class="q-pa-md">{{node}}: {{data.nodes}} nodes</h4>

		<q-markup-table dense separator="horizontal" bordered class="q-ma-md" style="max-width: 40%">
			<thead>
				<tr style="background-color: #eee">
					<th align="left">property-name</th>
					<th align="right">count</th>
					<th align="left">type</th>
				</tr>
			</thead>
			<tbody>
			<tr v-for="(v,p) in data.properties">
				<td class="code">{{p}}</td>
				<td class="code" align="right">{{v}}</td>
				<td class="code">{{data.types[p]}}</td>
			</tr>
			</tbody>
		</q-markup-table>

		<div class="q-pa-md row">
			<div class="cols-6">
				<textarea class="code" style="font-size:14px" ref="code" rows="20" cols="60">{{data.model}}</textarea>
			</div>
			<div class="cols-2" style="margin-left: 1em;">
				<q-btn color="primary" dense icon="fal fa-clone" label="copy" @click="copyCode"/>
			</div>

		</div>


	</div>

</div>
</template>
<script>
import { API } from '../tic'
export default {
  name: "CodeGenerator",
  data () {
  	return {
  		done: false,
  		node: '',
  		data: {}
  	}
  },

  methods: {

  	copyCode() {
  		this.$refs.code.select()
  		document.execCommand("copy");
  		console.log("on clip")
  	},

  	getnode() {
  		API.get(`/system/keys/${this.node}`).then(r => {
  			console.log("received: ", r.data)
  			this.data = r.data.data;
  			this.done = true

  		})
  	}

  }



 }
 </script>